import React, { Component } from 'react';
// import SystemResource from '../resources/System';
import HeaderButtons from '../components/HeaderButtons';
import { withIonLifeCycle } from '@ionic/react';
import { closeOutline as close } from 'ionicons/icons';
import moment from 'moment';
import {
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonContent,
  IonTitle,
  IonButtons,
  IonItem,
  IonCol,
  IonRow,
  IonChip,
  IonLabel,
  IonButton,
  IonModal,
  IonIcon,
  IonFooter,
  IonTextarea,
  IonPage,
  IonLoading,
} from '@ionic/react';

import ReservationsService from '../services/reservations';
import ProfileService from '../services/profile';

class ReservationEvent extends Component {
  constructor(props) {
    super(props);

    this.loader = React.createRef();
    this.selectedTimeRef = React.createRef('');
    this.selectedStaffIdRef = React.createRef('');
    this.serviceRef = React.createRef('');
    this.profileRef = React.createRef('');
    this.noteRef = React.createRef('');
    this.pickedDateRef = React.createRef('');
    this.state = {
      momentDate: moment(),
      pickedDateTime: null,
      service: [],
      pickedDate: '',
      availableSlots: [],
      event: [],
      loading: false,
      profile: false,
    };
  }

  async submitHandler(that) {
    that.setState({ loading: true });

    let endAppointment = moment(`${that.pickedDateRef.current} ${that.selectedTimeRef.current}`)
      .add(that.serviceRef.current.duration + that.serviceRef.current.padding_after, 'm')
      .format('HH:mm:ss');

    const { id } = this.props.match.params;

    try {
      await ReservationsService.saveReservation({
        user_id: that.profileRef.current.id,
        service_id: that.serviceRef.current.id,
        event_id: id,
        staff_id: that.selectedStaffIdRef.current,
        day: that.pickedDateRef.current,
        start_appointment: that.selectedTimeRef.current,
        end_appointment: endAppointment,
        note: that.noteRef.current,
      });

      await that.props.toast.current.add({ message: `Prenotaziona effettuata correttamente.` });
      that.setState({ isModalOpen: false });
      that.props.history.push('/app/reservations');
    } catch (e) {
      console.error(e);
      that.props.toast.current.add({
        message: `Problema nella conferma della prenotazione.`,
        color: 'danger',
      });
    } finally {
      that.setState({ loading: false });
    }
  }

  async ionViewWillEnter() {
    if (this.props.loader) {
      this.props.loader.current.show();
    }
    this.setState({
      profile: undefined,
    });

    try {
      const { id, data: selectedDate, serviceId } = this.props.match.params;

      // if (!this.state.profile) {
      //   let {
      //     data: { data: profile },
      //   } = await ProfileService.me();
      //   await this.setState({ profile });

      //   this.profileRef.current = profile;
      // }

      const response = await ReservationsService.pickDateEvent(id, selectedDate, serviceId);
      const { availableSlots, pickedDate, service, event } = response.data;

      this.setState({
        pickedDateTime: availableSlots,
        pickedDate: pickedDate,
        service: service,
        event: event,
      });

      this.serviceRef.current = service;
      this.pickedDateRef.current = pickedDate;
      if (!this.state.profile) {
        let {
          data: { data: profile },
        } = await ProfileService.me();
        await this.setState({ profile });

        this.profileRef.current = profile;
      }
    } catch (error) {
      console.error('Errore nel caricamento dei dati:', error.response || error.message || error);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewWillLeave() {
    this.setState({ isModalOpen: false });
    this.setState({ pickedDateTime: null });
    this.setState({ availableDate: null });
    this.setState({ isActive: '' });
    this.setState({ calendarDayProgression: [] });

    this.serviceRef.current = '';
    this.profileRef.current = '';
    this.selectedTimeRef.current = '';
    this.pickedDateRef.current = '';
    this.selectedStaffIdRef.current = '';
    this.noteRef.current = '';
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString('it-IT');
  }

  render() {
    const { pickedDate, pickedDateTime, isModalOpen, service, event, profile } = this.state;
    const formattedDate = this.formatDate(pickedDate);

    return (
      <>
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/app/events" />
              </IonButtons>
              <HeaderButtons {...this.props} />
              <IonTitle>
                Prenotazione {service.name} - Evento {event.name}
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <p className="ion-padding-top ion-padding-start ion-padding-end">
              Servizio di {service.name} previsto per il {formattedDate}, con una durata di {service.duration} minuti.
              <br></br>
              Descrizione del servizio: {service.description}
            </p>

            <IonItem className="ion-margin-vertical ion-padding-bottom ion-width">
              <IonCol className="ion-text-center">
                <h2>Seleziona una fascia oraria disponibile</h2>
                <IonRow className="ion-align-self-start ion-padding-top">
                  {pickedDateTime && pickedDateTime.length > 0 ? (
                    pickedDateTime.map((slot, index) => (
                      <IonChip
                        key={index}
                        color="success"
                        outline={true}
                        onClick={() => {
                          if (profile) {
                            this.setState({ isModalOpen: true });
                            this.selectedTimeRef.current = slot.time;
                            this.selectedStaffIdRef.current = slot.staffId;
                          } else {
                            const redirectUrl = `/app/reservations/event/${event.id}/${pickedDate}/${service.id}`;
                            this.props.history.replace(`/auth/login?redirect=${encodeURIComponent(redirectUrl)}`);
                          }
                        }}
                      >
                        <IonLabel>{slot.time}</IonLabel>
                      </IonChip>
                    ))
                  ) : (
                    <p>Nessuna fascia oraria disponibile</p>
                  )}
                </IonRow>
              </IonCol>
            </IonItem>
          </IonContent>
          {isModalOpen && (
            <IonModal isOpen={isModalOpen}>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton
                      onClick={() => {
                        this.selectedTimeRef.current = '';
                        this.selectedStaffIdRef.current = '';
                        this.setState({ isModalOpen: false });
                        this.noteRef.current = '';
                      }}
                    >
                      <IonIcon icon={close} />
                    </IonButton>
                  </IonButtons>
                  <IonTitle>Riepilogo</IonTitle>
                </IonToolbar>
              </IonHeader>

              <IonContent fullscreen>
                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>Dati Personali</b>
                    </IonLabel>
                  </ion-item-divider>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Nome:</h4>
                      <p className="ion-text-capitalize">{this.profileRef.current.name}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Email:</h4>
                      <p>{this.profileRef.current.email}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Telefono:</h4>
                      <p>{this.profileRef.current.phone_number}</p>
                    </IonLabel>
                  </IonItem>
                </ion-item-group>

                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>Prenotazione</b>
                    </IonLabel>
                  </ion-item-divider>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Servizio:</h4>
                      <p>{this.serviceRef.current.name}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Giorno:</h4>
                      <p>{moment(this.pickedDateRef.current).format('dddd | DD MMMM YYYY')}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Ora:</h4>
                      <p>{this.selectedTimeRef.current}</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Prezzo:</h4>
                      <p>{this.serviceRef.current.price} €</p>
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Durata:</h4>
                      <p>{this.serviceRef.current.duration} min</p>
                    </IonLabel>
                  </IonItem>
                </ion-item-group>

                <ion-item-group>
                  <ion-item-divider>
                    <IonLabel className="ion-text-uppercase" color="dark">
                      <b>Note</b>
                    </IonLabel>
                  </ion-item-divider>
                  <IonItem lines="none">
                    <IonLabel>
                      <h4 className="ion-text-uppercase">Note al farmacista:</h4>
                    </IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonTextarea
                      type="text"
                      name="note"
                      placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
                      value={this.noteRef.current}
                      onIonChange={(e) => (this.noteRef.current = e.detail.value)}
                    />
                  </IonItem>
                </ion-item-group>
              </IonContent>

              <IonFooter>
                <IonToolbar>
                  {this.profileRef.current ? (
                    <IonButton
                      className="ion-margin-horizontal"
                      expand="block"
                      color="success"
                      onClick={() => this.submitHandler(this)}
                    >
                      Conferma prenotazione
                    </IonButton>
                  ) : (
                    <IonButton
                      expand="block"
                      color="light"
                      className="ion-margin-horizontal"
                      onClick={() => {
                        this.setState({ isModalOpen: false });
                        this.props.history.push(`/auth/login?redirect=/app/reservations`);
                      }}
                    >
                      Accedi per richiedere informazioni
                    </IonButton>
                  )}
                </IonToolbar>
              </IonFooter>
              <IonLoading isOpen={this.state.loading} message={'Salvataggio in corso...'} duration={0} />
            </IonModal>
          )}
        </IonPage>
      </>
    );
  }
}

export default withIonLifeCycle(ReservationEvent);

import React, { Component } from 'react';

import { withIonLifeCycle } from '@ionic/react';
import { IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { IonText, IonButton } from '@ionic/react';

import ProfileService from '../services/profile';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    // console.log(this.form);
    this.state = {
      profile: {},
      errors: {},
    };

    this.inputHandler = this.inputHandler.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  async ionViewWillEnter() {
    try {
      const response = await ProfileService.me();
      const profile = response.data || {}; // La proprietà "data" contiene direttamente i dati
      this.setState({ profile });
    } catch (error) {
      console.error('Errore durante il caricamento del profilo:', error);
    }
  }

  async inputHandler(event) {
    let errors = this.state.errors;
    delete errors[event.target.name];

    let profile = this.state.profile;
    profile[event.target.name] = event.target.value;

    await this.setState({
      profile,
      errors,
    });
  }

  async handleValidation(event) {
    let errors = this.state.errors;
    let error = !!event.target ? event.target.firstChild.validationMessage : event.firstChild.validationMessage;
    let name = !!event.target ? event.target.name : event.name;

    delete errors[name];

    try {
      this.form.current[name].setCustomValidity('');
      this.form.current[name].reportValidity();
    } catch (error) {
      console.error(error);
    }

    if (name === 'password_confirmation') {
      this.handleValidation({ target: this.form.current.password.parentElement });
    }

    await this.setState({ errors });

    if (!!error) {
      errors[name] = error;
      await this.setState({ errors });
      await this.setState({ disabled: true });
      return;
    }
    await this.setState({ disabled: false });
  }

  async onEdit(e) {
    e.preventDefault();

    let [name, email, password, password_confirmation, phone_number, codice_fiscale] = [
      e.target.name.value,
      e.target.email.value,
      e.target.password.value,
      e.target.password_confirmation.value,
      e.target.phone_number.value.replaceAll(' ', '').replaceAll('-', ''),
      e.target.codice_fiscale.value,
    ];

    try {
      let response = await ProfileService.update({
        name,
        email,
        password,
        password_confirmation,
        phone_number,
        codice_fiscale,
      });

      if (!!this.props.onSubmit) {
        this.props.onSubmit(null, response);
      }
    } catch (error) {
      if (error.response) {
        let response = error.response.data.error;
        console.error(response);

        if (response instanceof Object) {
          for (const key in response) {
            if (response.hasOwnProperty(key)) {
              this.form.current[key].setCustomValidity('');
              this.form.current[key].setCustomValidity(response[key]);
              this.form.current[key].reportValidity();
              this.handleValidation({ target: this.form.current[key].parentElement });
            }
          }
        }
      }

      if (!!this.props.onSubmit) {
        this.props.onSubmit(error);
      }
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <form ref={this.form} onSubmit={this.onEdit}>
        <IonList lines="full" className="ion-no-margin ion-no-padding">
          <IonItem>
            <IonLabel color={!!errors.name ? 'danger' : null} position="floating">
              Nome e cognome
            </IonLabel>
            <IonInput
              type="text"
              name="name"
              onIonBlur={this.handleValidation}
              onIonChange={this.inputHandler}
              value={this.state.profile.name}
              required
            />
            {!!errors.name ? <IonText color="danger">{errors.name}</IonText> : null}
          </IonItem>

          <IonItem>
            <IonLabel color={!!errors.phone_number ? 'danger' : null} position="floating">
              Numero di telefono
              <small>&nbsp; Esempio:39 320 1234567</small>
            </IonLabel>
            <IonInput
              type="tel"
              name="phone_number"
              required
              placeholder="39 320 1234567"
              onIonBlur={this.handleValidation}
              onIonChange={this.inputHandler}
              pattern="[0-9]{2}[\s|\-]?[0-9]{3}[\s|\-]?[0-9]{7}"
              value={this.state.profile.phone_number}
            />
            {!!errors.phone_number ? <IonText color="danger">{errors.phone_number}</IonText> : null}
          </IonItem>

          <IonItem>
            <IonLabel color={!!errors.codice_fiscale ? 'danger' : null} position="floating">
              Codice fiscale
            </IonLabel>
            <IonInput
              type="text"
              name="codice_fiscale"
              required
              minlength={16}
              maxlength={16}
              pattern="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$"
              onIonBlur={this.handleValidation}
              onIonChange={this.inputHandler}
              value={this.state.profile.codice_fiscale}
              uppercase
              style={{ textTransform: 'uppercase' }}
            />
            {!!errors.codice_fiscale ? <IonText color="danger">{errors.codice_fiscale}</IonText> : null}
          </IonItem>

          <IonItem>
            <IonLabel color={!!errors.email ? 'danger' : null} position="floating">
              Email
            </IonLabel>
            <IonInput
              type="email"
              name="email"
              onIonBlur={this.handleValidation}
              onIonChange={this.inputHandler}
              value={this.state.profile.email}
              required
            />
            {!!errors.email ? <IonText color="danger">{errors.email}</IonText> : null}
          </IonItem>

          <IonItem>
            <IonLabel color={!!errors.password ? 'danger' : null} position="floating">
              Password
            </IonLabel>
            <IonInput
              type="password"
              name="password"
              clearInput={true}
              onIonBlur={this.handleValidation}
              onIonChange={this.inputHandler}
            />
            {!!errors.password ? <IonText color="danger">{errors.password}</IonText> : null}
          </IonItem>

          <IonItem>
            <IonLabel color={!!errors.password_confirmation ? 'danger' : null} position="floating">
              Conferma password
            </IonLabel>
            <IonInput
              type="password"
              name="password_confirmation"
              clearInput={true}
              onIonBlur={this.handleValidation}
              onIonChange={this.inputHandler}
            />
            {!!errors.password_confirmation ? <IonText color="danger">{errors.password_confirmation}</IonText> : null}
          </IonItem>
        </IonList>

        <div className="ion-padding">
          <IonButton expand="block" type="submit" className="ion-no-margin">
            Aggiorna
          </IonButton>
        </div>
      </form>
    );
  }
}

export default withIonLifeCycle(Profile);

import React, { Component } from 'react';
import { helpCircleOutline as help, arrowForwardOutline as forward, closeOutline as close } from 'ionicons/icons';
import moment from 'moment';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonPage,
  IonToolbar,
  IonButtons,
  IonList,
  IonFooter,
  IonRow,
  IonBackButton,
  IonTextarea,
  IonCardHeader,
  IonCardContent,
  withIonLifeCycle,
  IonCol,
  IonModal,
  IonButton,
  IonIcon,
  IonLabel,
  IonItem,
} from '@ionic/react';

import ShopService from '../services/shopservice';
import ProfileService from '../services/profile';
import ReservationsService from '../services/reservations';

class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.files = React.createRef();
    this.pickedDateRef = React.createRef('');
    this.selectedTimeRef = React.createRef('');
    this.selectedStaffIdRef = React.createRef('');
    this.noteRef = React.createRef('');
    this.date = React.createRef('');
    this.serviceRef = React.createRef('');
    this.profileRef = React.createRef('');

    this.state = {
      services: [],
      shopServiceModal: false,
      availableDate: [],
      pickedDateTime: null,
      isModalOpen: false,
      isActive: '',
      calendarDayProgression: [],
      category: [],
      selectedService: [],
      shopServices: [],
      selectedShopService: null,
      profile: null,
      shop_service_id: null,
    };

    this.setService = this.setService.bind(this);
    this.textHandler = this.textHandler.bind(this);
    this.requestService = this.requestService.bind(this);
  }

  async ionViewWillEnter() {
    // if (!!this.props.loader) this.props.loader.current.show();
    // const { data } = this.props.match.params;

    await this.setService(this.props.match.params.id, false);

    try {
      const { id } = this.props.match.params;

      let response = await ShopService.find(id);
      const { category, shopServices } = response.data.data;

      this.setState({
        category,
        shopServices,
        services: category?.services || [],
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.props.loader.current.dismiss();
    }
  }

  async ionViewWillLeave() {
    this.setState({ isModalOpen: false });
    this.setState({ pickedDateTime: null });
    this.setState({ availableDate: null });
    this.setState({ isActive: '' });
    this.setState({ calendarDayProgression: [] });

    this.serviceRef.current = '';
    this.profileRef.current = '';
    this.selectedTimeRef.current = '';
    this.pickedDateRef.current = '';
    this.selectedStaffIdRef.current = '';
    this.noteRef.current = '';
  }

  async textHandler(event) {
    await this.setState({
      [event.target.name]: event.detail.value,
    });
  }

  async setService(id) {
    const selectedService = this.state.services.find((service) => service.id === id);

    this.setState({ selectedService, shopServiceModal: true });
  }

  async requestService(e) {
    const shop_service_id = this.state.selectedShopService.id;
    const note = this.state.note;
    const selectedService = this.state.selectedShopService;

    try {
      let shopservice = {
        shop_service_id: shop_service_id,
        shop_service_name: selectedService.name,
        note: note,
      };

      await ShopService.create(shopservice);
      this.setState({
        shop_service_id: undefined,
        note: '',
        shopServiceModal: false,
      });

      this.props.toast.current.add({
        message: `Richiesta inviata correttamente`,
      });
    } catch (error) {
      console.error(error);
      this.props.toast.current.add({
        message: `Impossibile inviare la richiesta`,
        color: 'warning',
      });
    }
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString('it-IT');
  }
  async setServicePrenotabile(id) {
    const selectedShopService = this.state.shopServices.find((service) => service.id === id);
    this.setState({ selectedShopService });

    try {
      let {
        data: { data: profile },
      } = await ProfileService.me();

      await this.setState({ profile });
    } catch (error) {
      console.error(error);
    }

    await this.setState({
      shopServiceModal: true,
    });
  }
  reset() {
    this.pickedDateRef.current = '';
    this.setState({ pickedDateTime: null });
    this.setState({ isActive: '' });
  }

  render() {
    const { services, selectedService, category, shopServices, selectedShopService, profile } = this.state;

    return (
      <IonPage>
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonBackButton defaultHref="/app/home" />
              </IonButtons>
              <IonTitle>Prenota un Servizio</IonTitle>
              <IonButtons slot="end">
                <IonButton href="/app/help/Services">
                  <IonIcon icon={help} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          {category !== null ? (
            <IonContent>
              <p className="ion-padding-top ion-padding-start ion-padding-end">
                Ecco l'elenco dei nostri servizi, <br />
                seleziona il servizio che ti interessa
              </p>
              <IonList lines="full" className="ion-no-margin ion-no-padding">
                <IonItem>Servizi prenotabili</IonItem>
                {services.map((service) => (
                  <IonItem key={service.id} onClick={() => this.setService(service.id)}>
                    <IonLabel>{service.name}</IonLabel>
                    <IonIcon slot="end" icon={forward} />
                  </IonItem>
                ))}
              </IonList>

              {selectedService && (
                <IonModal isOpen={this.state.shopServiceModal}>
                  <IonHeader>
                    <IonToolbar>
                      <IonButtons slot="end">
                        <IonButton onClick={() => this.setState({ shopServiceModal: false })}>
                          <IonIcon icon={close} />
                        </IonButton>
                      </IonButtons>
                      <IonTitle>Categoria: {category.name}</IonTitle>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent>
                    <IonItem>
                      <p>{selectedService.name}</p>
                    </IonItem>
                    <div className="ion-padding" dangerouslySetInnerHTML={{ __html: selectedService.description }} />
                  </IonContent>
                  <IonContent>
                    <IonToolbar>
                      <IonList>
                        <ion-list-header>
                          <IonLabel className="ion-text-uppercase" color="success">
                            <b>Prenota un servizo</b>
                          </IonLabel>
                        </ion-list-header>

                        <IonItem
                          key={selectedService.id}
                          lines="full"
                          detail={true}
                          onClick={() => {
                            this.setState({ shopServiceModal: false });
                            this.props.history.push(`/app/reservations/${selectedService.id}`);
                          }}
                        >
                          <IonLabel slot="start">{selectedService.name}</IonLabel>
                          {!!selectedService.show_price && <IonLabel slot="end">€ {selectedService.price}</IonLabel>}
                        </IonItem>
                      </IonList>
                    </IonToolbar>
                  </IonContent>
                </IonModal>
              )}
            </IonContent>
          ) : (
            <IonContent>
              <IonList lines="full" className="ion-no-margin ion-no-padding">
                <p className="ion-padding-top ion-padding-start ion-padding-end">
                  Ecco l'elenco dei nostri servizi su richiesta, <br />
                  seleziona il servizio che ti interessa
                </p>
                <IonItem>Servizi su richiesta</IonItem>
                {shopServices.map((shopService, i) => {
                  return (
                    <IonItem key={i} onClick={() => this.setServicePrenotabile(shopService.id)}>
                      <IonLabel>{shopService.name}</IonLabel>
                      <IonIcon slot="end" icon={forward} />
                    </IonItem>
                  );
                })}
              </IonList>
              {!!selectedShopService && (
                <IonModal isOpen={this.state.shopServiceModal}>
                  <IonHeader>
                    <IonToolbar>
                      <IonButtons slot="end">
                        <IonButton
                          onClick={() => this.setState({ shopServiceModal: false, shop_service_id: undefined })}
                        >
                          <IonIcon icon={close} />
                        </IonButton>
                      </IonButtons>
                      <IonTitle>Richiedi informazioni sul Servizio</IonTitle>
                    </IonToolbar>
                  </IonHeader>

                  <IonContent>
                    <IonItem>
                      <p>{selectedShopService.name}</p>
                    </IonItem>
                    <div
                      className="ion-padding"
                      dangerouslySetInnerHTML={{ __html: selectedShopService.description }}
                    />
                  </IonContent>

                  <IonFooter>
                    <IonToolbar>
                      {selectedShopService.sdg_services[0] ? (
                        <IonList>
                          <ion-list-header>
                            <IonLabel className="ion-text-uppercase" color="success">
                              <b>Prenota i servizi</b>
                            </IonLabel>
                          </ion-list-header>
                          {selectedShopService.sdg_services.map((sdgService) => {
                            return (
                              <IonItem
                                key={sdgService.id}
                                lines="full"
                                detail={true}
                                onClick={() => {
                                  this.setState({ shopServiceModal: false });
                                  this.props.history.push(`/app/reservations/${sdgService.id}`);
                                }}
                              >
                                <IonLabel slot="start">{sdgService.name}</IonLabel>
                                {!!sdgService.show_price && <IonLabel slot="end">€ {sdgService.price}</IonLabel>}
                              </IonItem>
                            );
                          })}
                        </IonList>
                      ) : (
                        <>
                          {profile ? (
                            <>
                              <IonItem>
                                <p>
                                  Sarai ricontattato al numero di telefono {profile.phone_number} oppure alla email{' '}
                                  {profile.email} che hai inserito nel &nbsp;
                                  <IonButton
                                    color="light"
                                    size="small"
                                    onClick={() => {
                                      this.setState({ shopServiceModal: false, shop_service_id: undefined });
                                      this.props.history.push(`/app/profile`);
                                    }}
                                  >
                                    tuo profilo
                                  </IonButton>
                                </p>
                                <IonLabel position="stacked">Note aggiuntive</IonLabel>
                                <IonTextarea
                                  type="text"
                                  name="note"
                                  placeholder="Indica qui se hai richieste particolari da inviare al Farmacista"
                                  value={this.state.note}
                                  onIonChange={this.textHandler}
                                />
                              </IonItem>
                              <IonButton
                                expand="block"
                                color="success"
                                onClick={this.requestService}
                                className="ion-margin-horizontal"
                              >
                                Richiedi informazioni
                              </IonButton>
                            </>
                          ) : (
                            <>
                              <IonToolbar>
                                <IonButton
                                  className="ion-margin-horizontal"
                                  expand="block"
                                  color="light"
                                  onClick={() => {
                                    this.setState({ shopServiceModal: false, shop_service_id: undefined });
                                    const redirectUrl = `/app/service/category/shopServices`;
                                    this.props.history.replace(
                                      `/auth/login?redirect=${encodeURIComponent(redirectUrl)}`
                                    );
                                  }}
                                >
                                  Accedi per richiedere informazioni
                                </IonButton>
                              </IonToolbar>
                            </>
                          )}
                        </>
                      )}
                    </IonToolbar>
                  </IonFooter>
                </IonModal>
              )}
            </IonContent>
          )}
        </>
      </IonPage>
    );
  }
}

export default withIonLifeCycle(ServiceDetails);

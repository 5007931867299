import { useState } from 'react';
import ShopServiceService from '../../services/shopevent';

const useEvents = () => {
  const [events, setServices] = useState({ events: [] });

  // const [servicesLoading, setServicesLoading] = useState()
  // const [servicesError, setServicesError] = useState()

  const loadEvents = async () => {
    try {
      // setServicesLoading(true)
      const resp = await ShopServiceService.get();
      setServices(resp.data.data);
    } catch (error) {
      // setServicesError(error)
    }
    // finally {
    //     setServicesLoading(false)
    // }
  };

  // return [services, servicesLoading, servicesError, loadServices]
  return [events, loadEvents];
};

export default useEvents;
